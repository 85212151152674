<template>
  <div class="pay">
    <div class="box">
      <div class="txt3">{{ province }}报考通道生效中</div>
      <div class="txt1" @click="getPay">
        ¥{{ amountShow }} 立即报考,领取政府津贴
      </div>
    </div>
    <van-count-down :time="total2" format="HH 时 mm 分 ss 秒">
      <template #default="timeData">
        <span class="t1">{{
          timeData.hours > 9 ? timeData.hours : "0" + timeData.hours
        }}</span>
        <span class="colon">时</span>
        <span class="t1">{{
          timeData.minutes > 9 ? timeData.minutes : "0" + timeData.minutes
        }}</span>
        <span class="colon">分</span>
        <span class="t1">{{
          timeData.seconds > 9 ? timeData.seconds : "0" + timeData.seconds
        }}</span>
        <span class="colon">秒 恢复原价 ¥499</span>
      </template>
    </van-count-down>
    <van-popup
      v-model="showRandom"
      :close-on-click-overlay="false"
      class="random"
    >
      为您立减{{ randomNum }}元
    </van-popup>
    <van-popup
      v-model="showCoupon"
      close-icon="close"
      :close-on-click-overlay="false"
      closeable
      @closed="showTeacherToast"
    >
      <div class="pop-content">
        <div class="pop-blank"></div>
        <div class="pop-img popimg10" v-if="showCouponMoney == 10">
          <div class="txt2">{{ province }}省消费券补贴</div>
          <div class="txt1">@{{ empName }}专享</div>
        </div>
        <div class="pop-img popimg20" v-if="showCouponMoney == 20">
          <div class="txt2">{{ province }}省消费券补贴</div>
          <div class="txt1">@{{ empName }}专享</div>
        </div>
        <div class="pop-img popimg30" v-if="showCouponMoney == 30">
          <div class="txt2">{{ province }}省消费券补贴</div>
          <div class="txt1">@{{ empName }}专享</div>
        </div>
        <div class="pop-btn" @click="getCoupon"></div>
      </div>
    </van-popup>
    <van-popup
      v-model="showTeacher"
      :close-on-click-overlay="false"
      closeable
      close-icon="close"
    >
      <div class="teacher-pop-content">
        <div class="pop-blank"></div>
        <div class="pop-img">
          <img src="@/assets/images/pay/pop-teacher.png" alt="" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { accSub, Encrypt } from "@/utils/common.js";
import wx from "weixin-js-sdk";
import {
  getRandom,
  getOrder,
  getInfo,
  notifyPaySuccess,
  //juliangHD,
} from "@/api/pay";
import { Toast } from "vant";
export default {
  //mixins: [common],
  data() {
    return {
      province: "辽宁",
      m0: 99, // 初始数值
      m1: 99, // 减后数值
      realMoney: 199,
      amountShow: 99, // 变化值
      total2: 0,
      showRandom: false, // 随机满减
      randomNum: 0,
      showCoupon: false, // 补贴券
      empName: "",
      showTeacher: false, // 老师微信
      ifCoupon: false, // 是否已领取过补贴券
      orderConfig: {},
      openId: "",
      unionId: "",
      clickid: "",
      isCoupons:0,//是否使用优惠卷
      showCouponMoney:0,
    };
  },
  async mounted() {
    this.getRandom();
    //const { openId, unionId } = this.$store.state;
    this.unionId = this.$store.getters["getUnionId"];
    this.openId = this.$store.getters["getOpenId"];
    const result = await getInfo({ unionId: this.unionId });
    const { region, userName, clickid } = result.data;
    this.province = region;
    this.empName = userName;
    this.clickid = clickid;
  },
  methods: {
    showTeacherToast() {
      if (!this.ifCoupon) {
        const that = this;
        setTimeout(() => {
          that.showTeacher = true;
        }, 2000);
      }
    },
    applayShowCouponMoney() {
      let that = this;
      let money = that.showCouponMoney;
      if(money == 10){
        that.showCouponMoney = 20
      }else if(money == 20){
        that.showCouponMoney = 30
      }else{
        that.showCouponMoney = 10
      }
      that.showCoupon = true;
    },
    // 领取优惠券
    getCoupon() {
      this.ifCoupon = true;
      this.m1 = accSub(this.realMoney, this.showCouponMoney);
      this.amountShow = this.m1;
      this.isCoupons=1;
      this.getPay();
      this.showCoupon = false;
    },
    changeAmountFrame() {
      const frameNum = 60;
      let amoutFrame = this.m0;
      const _this = this;
      // eslint-disable-next-line no-unused-vars
      let animation = window.requestAnimationFrame(function f() {
        if (amoutFrame > _this.m1) {
          amoutFrame = amoutFrame - (_this.m0 - _this.m1) / frameNum;
          // 动画继续
          animation = window.requestAnimationFrame(f);
        } else {
          amoutFrame = _this.m1;
          // 动画停止
          window.cancelAnimationFrame(f);
        }

        _this.amountShow = _this.amountFormat(amoutFrame);
      });
    },
    amountFormat(num) {
      //判断是否是数字
      if (!this.isNum(num)) {
        return "-";
      }
      num = Number(num);
      let numStr = String(num);
      let [integer, decimals] = numStr.split(".");

      //整数部分
      let integerRes = "";
      for (let i = integer.length - 1; i >= 0; i--) {
        if (
          i % 3 === 0 &&
          i !== integer.length - 1 &&
          i !== integer.length - 2 &&
          i !== integer.length - 3 &&
          integer[i] !== "-"
        ) {
          integerRes = integer[i] + "," + integerRes;
        } else {
          integerRes = integer[i] + integerRes;
        }
      }
      //小数部分
      decimals = decimals ? decimals.substring(0, 2) : "";
      let result = decimals ? integerRes + "." + decimals : integerRes + ".00";
      return result;
    },

    isNum(num) {
      if (!isNaN(num) && typeof num !== "boolean" && num !== "") {
        return true;
      }
      return false;
    },
    // 调微信支付
    async getPay() {
      this.getOrder();
    },
    getRandom() {
      getRandom({})
        .then((res) => {
          this.randomNum = res;
          this.showRandom = true;
          setTimeout(() => {
            let value = accSub(this.m0, this.randomNum);
            this.m1 = value;
            this.realMoney = value;
            this.changeAmountFrame();
            this.showRandom = false;
            this.total2 = 6 * 3600 * 1000;
          }, 2000);
        })
        .catch((err) => {
          console.log("获取随机优惠金额 res: ", err);
        });
    },
    //
    async getOrder() {
      //this.m1 = 0.01;
      getOrder({
        prices: Encrypt(this.m1),
        openId: this.openId,
        unionId: this.unionId,
        isCoupons:this.isCoupons
      })
        .then((res) => {
          const config = res.data;
          this.orderConfig = config;
          const that = this;

          // 方式二：
          wx.chooseWXPay({
            timestamp: config.timeStamp,
            timeStamp: config.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: config.nonceStr, // 支付签名随机串，不长于 32 位
            package: config.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: config.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: config.paySign, // 支付签名
            success: function () {
              notifyPaySuccess({ orderNo: config.orderNo });

              // 巨量引擎回调
              // if (this.clickid) {
              //   juliangHD({
              //     event_type: "customer_effective",
              //     context: {
              //       ad: {
              //         callback: that.clickid, //callback 这里需要填写的就是从启动参数里获取的 clickid
              //       },
              //     },
              //     timestamp: new Date().getTime(),
              //   });
              // }

              that.$store.commit("SET_PAYED", true);
              Toast.success("支付成功");
              setTimeout(() => {
                that.$router.replace({ path: "/lesson" });
              }, 500);
            },
            fail: function () {
              Toast.fail("支付失败,请稍后重新支付");
              // setTimeout(() => {
              //   that.showTeacher = true;
              // }, 1000);
            },
            cancel: function () {
              //Toast.fail("取消支付");
              // if (!that.ifCoupon) {
              //   that.showCouponMoney = 10;
              //   that.showCoupon = true;
              // } else {
              //   that.showTeacher = true;
              // }
              that.applayShowCouponMoney();
            },
          });
        })
        .catch((err) => {
          console.log("获取订单号: ", err);
        });
    },
    // juliangHD() {
    //   juliangHD({
    //     event_type: "customer_effective",
    //     context: {
    //       ad: {
    //         callback: this.clickId, //callback 这里需要填写的就是从启动参数里获取的 clickid
    //       },
    //     },
    //     timestamp: new Date().getTime(),
    //   })
    //     .then((res) => {
    //       console.log(res.code);
    //     })
    //     .catch((err) => {
    //       console.log("巨量回调失败: ", err);
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
.pay {
  font-size: 14px;
  background-image: url(~@/assets/images/pay/bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 161%;
  height: 100vh;
  letter-spacing: 0.5px;
  padding-top: 5%;
  box-sizing: border-box;
  position: relative;
  .box {
    background-image: url(~@/assets/images/pay/bg-12.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 134.7%;
    width: 90%;
    margin: 0 5%;
    position: relative;

    .txt3 {
      // font-family: "Adobe-Std-R", "微软雅黑", "Arial Narrow", Arial, sans-serif;
      position: absolute;
      color: #222;
      font-size: 13.5px;
      line-height: 18px;
      left: 20%;
      top: 67.5%;
      width: 25%;
      text-align: center;
    }
    .txt1 {
      position: absolute;
      color: #000821;
      font-size: 15px;
      width: 100%;
      text-align: center;
      top: 93.5%;
    }
  }

  .van-popup {
    background-color: transparent;
  }
  .random {
    color: #fff;
    background-color: #17191a;
    padding: 10px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .pop-content {
    .pop-blank {
      height: 40px;
    }
    .popimg10{
      background-image: url(~@/assets/images/pay/pop-img.png);
    }
    .popimg20{
      background-image: url(~@/assets/images/pay/pop-img20.png);
    }
    .popimg30{
      background-image: url(~@/assets/images/pay/pop-img30.png);
    }
    .pop-img {
      width: 80vw;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      padding-bottom: 90%;
      .txt1 {
        position: absolute;
        color: #282828;
        font-size: 10px;
        top: 48%; //42%;
        width: 100%;
        text-align: center;
      }
      .txt2 {
        position: absolute;
        color: #000821;
        font-size: 14px;
        top: 40%; //33%;
        font-weight: 600;
        width: 100%;
        text-align: center;
      }
    }
    .pop-btn {
      width: 60vw;
      margin: 0 10vw;
      background-image: url(~@/assets/images/pay/pop-btn.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      padding-bottom: 13.5%;
      margin-top: 10%;
    }
  }
  .teacher-pop-content {
    .pop-blank {
      height: 80px;
    }
    .pop-img {
      // width: 80vw;
      // background-image: url(~@/assets/images/pay/pop-teacher.png);
      // background-repeat: no-repeat;
      // background-position: center center;
      // background-size: cover;
      // padding-bottom: 117.6%;
    }
    img {
      width: 80vw;
    }
  }
  .van-count-down {
    margin-top: 20px;
    width: 100%;
    .t1 {
      background-image: url(~@/assets/images/pay/time.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      //padding-bottom: 95%;
      color: #0515e9;
      font-size: 14px;
      padding: 0 2px;
      margin: 0 4px;
      display: inline-block;
    }
    .colon {
      color: #fff;
      font-size: 12px;
    }
  }
}
</style>
