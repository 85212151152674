<template>
  <div class="notice">
    <div class="box">
      <h1 style="text-align: center">
        <strong
          ><strong><strong>报考须知</strong></strong></strong
        >
      </h1>

      <h2>
        <strong
          ><strong><strong>一、报考准备</strong></strong></strong
        >
      </h2>

      <p>
        在报考社会工作者证之前，考生需要准备一些材料，包括身份证、学历证书、报考资格证明等。身份证是报考必须的，考生可以在身份证中查看自己的基本信息，并在报考时准备好。学历证书是报考的重要依据，考生可以通过学历证书来证明自己的学历，以便报考社会工作者证。此外，考生还需要准备报考资格证明，以证明自己有报考资格。报考资格证明可以在报考网站上获取，考生可以根据自己的报考要求，选择合适的报考资格证明。准备完上述材料后，考生就可以开始报考社会工作者证了。
      </p>

      <p>报考社会工作者证的流程主要分为以下几个步骤：</p>

      <p>
        1、确定报考资格。首先，考生需要确定自己是否符合报考社会工作者证的资格要求，包括学历、年龄等。
      </p>

      <p>
        2、网上报名。考生可以在报考网站上进行网上报名，填写报名表，上传报考资料，完成报名。
      </p>

      <p>
        3、缴纳报考费用。考生需要根据报考网站的要求，按时缴纳报考费用，以保证报考有效。
      </p>

      <p>
        4、参加考试。考生在报考网站上查看考试时间，按时参加考试，根据考试要求完成考试。
      </p>

      <p>
        5、准考证领取。考生可以在报考网站上查看准考证领取时间，按时领取准考证，以便参加考试。
      </p>

      <p>
        6、考试成绩查询。考生可以在报考网站上查看考试成绩，根据考试成绩判断自己是否通过考试。
      </p>

      <p>
        以上就是社会工作者证报考流程，考生们可以按照上述流程，准备好材料，了解报考流程，以便更好地报考社会工作者证。社会工作者证的报考流程是报考社会工作者证的必经之路，考生们要认真对待，准备充分，以便取得更好的成绩。
      </p>

      <h2>
        <strong
          ><strong><strong>二、报名时间</strong></strong></strong
        >
      </h2>

      <p>
        各省份报名时间与准考生打印时间并不一致，考试时间一致。因此考生需要实时关注所在考区公告，各省份使用统一报名网站：中国人事考试网。特别提醒下，首次报名的考生务必提前注册，网上报名系统将对身份、学历（学位）信息在线核查！报过其他资格考试并注册过账号可以省去这一环节，直接登录，接下来的流程主要针对第一次报名的考生。
      </p>

      <p>例如2025年的报名时间：</p>

      <p>网上报名：2025年4月7日9∶00～17日16∶00</p>

      <p>网上缴费：2025年4月7日9:00－4月18日17:00</p>

      <p>准考证打印：2025年6月11日9:00－6月14日17:00</p>

      <table cellspacing="0" style="border-collapse: collapse; width: 280px">
        <tbody>
          <tr>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: 1px outset black;
                border-right: 1px outset black;
                border-top: 1px outset black;
                vertical-align: center;
              "
            >
              <p>
                <strong><strong>日期</strong></strong>
              </p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: 1px outset black;
                vertical-align: center;
              "
            >
              <p>
                <strong><strong>考试时间</strong></strong>
              </p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: 1px outset black;
                vertical-align: center;
              "
            >
              <p>
                <strong><strong>考试科目</strong></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: 1px outset black;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>6月15日</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>14：00-16：00</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>社会工作法规与政策</p>
            </td>
          </tr>
          <tr>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: 1px outset black;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>6月16日</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>9：00-11：00</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>社会工作综合能力（初、中级）</p>
            </td>
          </tr>
          <tr>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: 1px outset black;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>&nbsp;</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>14：00-16：00</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>社会工作实务（初级）</p>
            </td>
          </tr>
          <tr>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: 1px outset black;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>&nbsp;</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>14：00-16：30</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>社会工作实务（中级）</p>
            </td>
          </tr>
          <tr>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: 1px outset black;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>&nbsp;</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>14：00-17：00</p>
            </td>
            <td
              style="
                background-color: #ffffff;
                border-bottom: 1px outset black;
                border-left: none;
                border-right: 1px outset black;
                border-top: none;
                vertical-align: center;
              "
            >
              <p>社会工作实务（高级）</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h2>
        <strong
          ><strong><strong>三、考试科目和作答方式</strong></strong></strong
        >
      </h2>

      <p>
        （一）助理社会工作师考试科目分为《社会工作综合能力（初级）》和《社会工作实务（初级）》2个科目。社会工作师考试科目分为《社会工作综合能力（中级）》、《社会工作实务（中级）》和《社会工作法规与政策》3个科目。社会工作师考试《社会工作实务（中级）》科目试题为主观题，在专用答题卡上作答；社会工作师考试其余两个科目和助理社会工作师考试各科目试题均为客观题，在普通答题卡上作答。高级社会工作师考试《社会工作实务（高级）》科目试题为主观题。主观题用黑色墨水笔在答题纸（专用答题卡）上作答。
      </p>

      <p>
        （二）客观题用2B铅笔在答题卡上作答，主观题用黑色墨水笔在答题纸（专用答题卡）上作答。报考人员应考时，可携带黑色墨水笔、2B铅笔和橡皮等文具，严禁携带计算器，其它物品应统一放置考场指定处。考场上应备有草稿纸供考生使用，考后收回。
      </p>

      <h2>
        <strong
          ><strong><strong>四、考试内容</strong></strong></strong
        >
      </h2>

      <p>考试报名都是在中国人事网进行，全国统考，笔试，60 分通关。</p>

      <p>（一）助理社会工作师考试报考人员：</p>

      <p>
        初级考试两门，综合+实务，须在1个考试年度内通过全部2个应试科目，方可取得助理社会工作师职业水平证书；
      </p>

      <p>（二）社会工作师考试报考人员：</p>

      <p>
        中级考试三门，综合+实务+法规，须在连续2个考试年度内通过全部3个应试科目，方可获得社会工作师职业水平证书。
      </p>

      <p>（三）高级社会工作师报考人员：</p>

      <p>应试人员通过该应试科目，可取得高级社会工作师考试成绩合格证明。</p>

      <h2>
        <strong
          ><strong><strong>五、报考条件</strong></strong></strong
        >
      </h2>

      <p>
        凡符合原人事部、民政部《关于印发〈社会工作者职业水平评价暂行规定〉和〈助理社会工作师、社会工作师职业水平考试实施办法〉的通知》（国人部发〔2006〕71号）报名条件规定的人员均可报名参加助理社会工作师、社会工作师职业水平考试。
      </p>

      <p>
        凡符合《人力资源社会保障部 民政部关于印发〈高级社会
        工作师评价办法〉的通知》（人社部规〔2018〕2号）报名条件规定的人员均可报名参加高级社会工作师职业水平考试。
      </p>

      <p>（一）报考人员报考条件</p>

      <p>
        凡中华人民共和国公民以及香港、澳门居民，遵守国家法律、法规，恪守职业道德，可报考相应级别的考试。
      </p>

      <p>1．符合下列报名条件之一的可报考助理社会工作师</p>

      <p>①取得高中或者中专学历，从事社会工作满4年；</p>

      <p>②取得社会工作专业大专学历，从事社会工作满2年；</p>

      <p>③社会工作专业本科应届毕业生；</p>

      <p>④取得其他专业大专学历，从事社会工作满4年；</p>

      <p>⑤取得其他专业本科及以上学历或学位，从事社会工作满2年。</p>

      <p>2．符合下列报名条件之一的可报考社会工作师</p>

      <p>
        ①取得高中或者中专学历，并取得助理社会工作师职业水平证书后，从事社会工作满6年；
      </p>

      <p>②取得社会工作专业大专学历，从事社会工作满4年；</p>

      <p>③取得社会工作专业大学本科学历，从事社会工作满3年；</p>

      <p>④取得社会工作专业硕士学位，从事社会工作满1年；</p>

      <p>⑤取得社会工作专业博士学位；</p>

      <p>⑥取得其他专业大专及以上学历或学位，其从事社会工作年限相应增加2年。</p>

      <p>3．同时具备以下条件的可报考高级社会工作师</p>

      <p>
        ①拥护中国共产党领导，遵守国家宪法、法律、法规，热爱社会工作事业，具有良好的职业道德；
      </p>

      <p>②具有本科及以上学历（或学士及以上学位）；</p>

      <p>
        ③在通过全国社会工作者职业水平考试取得社会工作师（中级）资格后，从事社会工作满5年，其截止日期为2023年12月31日。
      </p>

      <p>
        （二）助理社会工作师考试报名条件中的&ldquo;社会工作专业本科应届毕业生&rdquo;解释为&ldquo;社会工作专业本科应届毕业生和已经取得社会工作专业本科及以上学历（学位）的人员&rdquo;。
      </p>

      <p>
        （三）持香港、澳门、台湾地区或者国外高等学校学历或者学位证书报考的，其学历、学位证书须经教育部留学服务中心认证；符合报名条件的香港、澳门及台湾居民可以申请参加社会工作者职业水平考试。
      </p>

      <p>
        （四）持有中国残疾人联合会颁发的残疾人证二级低视力
        （或严重于二级）者可申请借助读屏软件参加考试。报名参加考试的视力障碍人员，须向报考地人事考试机构申请使用专用考场。
      </p>

      <p>
        （五）报名条件中有关工作年限的要求，是指报名人员取得学历前后从事本专业工作时间的总和，其截止日期为2023年12月31日。
      </p>

      <h2>
        <strong
          ><strong><strong>六、社工证领取时间/方式</strong></strong></strong
        >
      </h2>

      <p>
        社会工作者考试证书领取一般在成绩公布后2-3个月左右进行，领取地点一般为当地人事考试中心或者民政局、社工中心，具体以当地人事考试机构通知为准。
      </p>

      <h2>
        <strong
          ><strong><strong>七、社工证领取方式</strong></strong></strong
        >
      </h2>

      <p>社会工作者合格证书领取方式分为两种：</p>

      <p>第一种：邮寄服务</p>

      <p>
        目前部分地区提供邮寄服务，考生填写邮寄地址，付邮费后就可以等待证书寄出了。相信大部分上班族会选择这种方式，因为比较方便。
      </p>

      <p>第二种：现场领取</p>

      <p>
        社会工作者职业合格证书领取方式各地区不同，往年可以是现场领取或者是由他人代领都可以。
      </p>

      <p>
        （1）个人领证：凭本人有效身份证原件或成绩单领取(成绩单在查询成绩时可以直接打印)。
      </p>

      <p>
        （2）他人(单位)代领：凭代领人及持证人有效身份证原件，但是个别省禁止他人代领。详细信息还请随时关注自己省份的相关信息。
      </p>

      <p>
        注意：很多地区对社会工作者职业合格证书获得者发放职业技能提升补贴，发放金额几百元到千元不等，大家领取证书后可以留意当地的奖励政策，及时进行申报。
      </p>

      <h2>
        <strong
          ><strong><strong>八、社工证领取登记</strong></strong></strong
        >
      </h2>

      <p>
        社工证到手后，也要把证书登记的事情放在心上，可千万别以为拿到证书后，就万事大吉啦，根据中国人事考试网发布的通知：社会工作者职业水平证书实行登记服务制度。水平证书持有者按有关规定办理登记手续。社会工作者职业水平证书登记分为首次登记和再登记。首次登记的受理期限为通过社会工作者职业水平评价后1年内，登记有效期为3年。首次登记后，每3年进行再登记。再登记的受理期限为上次登记有效期满前3个月。一般，各省市的民政局官网会发布证书登记办理通知。由于社会工作者证书的登记工作，由省、自治区、直辖市人民政府民政部门负责，所以各地区证书登记办理方式各有不同。关于社工证登记的具体时间和操作流程，大家应以所在省市民政部发布的&ldquo;社会工作者职业水平证书登记办理&rdquo;为准。
      </p>

      <p>&nbsp;</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.notice {
  margin: 0;
  padding: 0;
  clear: both;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: left;
}
.box {
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  overflow-y: scroll;
}
h1{
    font-size: 18px;
}
h2{
    font-size: 14px;
}
p {
  font-size: 14px;
  text-indent: 15px;
}
</style>